import React, { useEffect } from "react";
import sal from "sal.js";

import PricingData from "@/data/home.json";

import SinglePrice from "./PricingProps/SinglePrice";
import SinglePriceMonth from "./PricingProps/SinglePriceMonth";
import SinglePriceYear from "./PricingProps/SinglePriceYear";
import Compare from "./Compare";

const PricingTwo = ({
  parentClass,
  childClass,
  start,
  end,
  isHeading,
  gap,
}) => {
  // useEffect(() => {
  //   sal();

  //   const cards = document.querySelectorAll(".bg-flashlight");

  //   cards.forEach((bgflashlight) => {
  //     bgflashlight.onmousemove = function (e) {
  //       let x = e.pageX - bgflashlight.offsetLeft;
  //       let y = e.pageY - bgflashlight.offsetTop;

  //       bgflashlight.style.setProperty("--x", x + "px");
  //       bgflashlight.style.setProperty("--y", y + "px");
  //     };
  //   });
  // }, []);

  return (
    <>
      <div className="main-content">
        <div
          className={`rainbow-pricing-area ${
            gap ? "rainbow-section-gap rainbow-section-gapTop-big" : ""
          }`}
        >
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  {isHeading ? (
                    <div className="section-title text-center">
                      <h4 className="subtitle">
                        <span className="theme-gradient">Pricing</span>
                      </h4>
                      <h1 className="title w-600 mb--20">
                        Gain access to 100s of Tools <br /> for the price of Starbucks
                      </h1>
                      <p className="description b1">
                        For only $9.99 per month charged annually, you can access all of our tools
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <nav className="cre8tegpt-tab">
                    <div
                      className="tab-btn-grp nav nav-tabs mb-3 text-center justify-content-center"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Monthly
                      </button>
                      <button
                        className="nav-link active with-badge"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Yearly
                        <span className="rainbow-badge-card badge-border">
                          35% Off
                        </span>
                      </button>
                    </div>
                  </nav>
                </div>
              </div>

              <div className={childClass} id="nav-tabContent">
                <div
                  className="tab-pane fade"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row row--15 mt_dec--30">
                    {PricingData &&
                      PricingData.pricing
                        .slice(start, end)
                        .map((data, index) => (
                          <SinglePriceMonth
                            {...data}
                            data={data}
                            key={index}
                            parentClass={parentClass}
                            increasePrice={false}
                          />
                        ))}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="row row--15">
                    {PricingData &&
                      PricingData.pricing
                        .slice(start, end)
                        .map((data, index) => (
                          <SinglePriceYear
                            {...data}
                            data={data}
                            key={index + 4}
                            parentClass={parentClass}
                            increasePrice={true}
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <Compare /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingTwo;
